//@flow
import gql from 'graphql-tag';

export const getGoogleSafetyCsv = gql`
  query MobileAppGetGoogleSafetyCsvQuery($id: ID!) {
    mobile_app_get_google_safety_csv(id: $id) {
      headers {
        code_name
        file_name
      }
      rows {
        answer_requirement
        question_id
        question_label
        response_id
        response_value
      }
    }
  }
`;

export const get = gql`
  query MobileAppGetQuery($id: ID!, $include: String) {
    mobile_app_get(id: $id, include: $include) {
      id
      name
      platform
      app_protection_score
      app_protection_score_ratio
      blocked_scan_reason
      category
      date_updated
      date_created
      app_secure_id
      integrations {
        ci_cd
        issue_tracker
        trust_kit
      }
      max_app_protection_score
      subscription
      release_type
      bundle_id
      is_enterprise_internal
      app_store_customer_mobile_app_id
      icon_url
      store_url
      scan_status
      most_recent_scan {
        id
        date_created
        date_updated
        mobile_app_id
        app_version
        start_date
        end_date
      }
      metadata {
        closed_security_finding_apple_app_store_blocker_beyond_subscription_level_count
        closed_security_finding_apple_app_store_blocker_count
        closed_security_finding_beyond_subscription_level_count
        closed_security_finding_count
        closed_security_finding_google_play_blocker_beyond_subscription_level_count
        closed_security_finding_google_play_blocker_count
        closed_security_finding_high_beyond_subscription_level_count
        closed_security_finding_high_count
        closed_security_finding_low_beyond_subscription_level_count
        closed_security_finding_low_count
        closed_security_finding_medium_beyond_subscription_level_count
        closed_security_finding_medium_count
        closed_security_finding_priority_alerts_beyond_subscription_level_count
        closed_security_finding_priority_alerts_count
        closed_security_finding_security_p1_beyond_subscription_level_count
        closed_security_finding_security_p1_count
        closed_security_finding_regulatory_compliance_count
        closed_security_finding_regulatory_compliance_beyond_subscription_level_count
        open_insight_finding_count
        open_sdk_finding_beyond_subscription_level_count
        open_sdk_finding_beyond_subscription_level_with_open_issues_beyond_subscription_level_count
        open_sdk_finding_count
        open_sdk_finding_with_open_issues_beyond_subscription_level_count
        open_sdk_finding_with_open_issues_count
        open_security_finding_apple_app_store_blocker_beyond_subscription_level_count
        open_security_finding_apple_app_store_blocker_count
        open_security_finding_beyond_subscription_level_count
        open_security_finding_count
        open_security_finding_google_play_blocker_beyond_subscription_level_count
        open_security_finding_google_play_blocker_count
        open_security_finding_high_beyond_subscription_level_count
        open_security_finding_high_count
        open_security_finding_low_beyond_subscription_level_count
        open_security_finding_low_count
        open_security_finding_medium_beyond_subscription_level_count
        open_security_finding_medium_count
        open_security_finding_priority_alerts_beyond_subscription_level_count
        open_security_finding_priority_alerts_count
        open_security_finding_security_p1_beyond_subscription_level_count
        open_security_finding_security_p1_count
        open_security_finding_regulatory_compliance_count
        open_security_finding_regulatory_compliance_beyond_subscription_level_count
      }
      keys_to_the_kingdom {
        open_security_finding_count
        open_security_finding_target_count
      }
      coffee_shop_compromise {
        open_security_finding_count
        open_security_finding_target_count
      }
      app_store_privacy {
        data_record_types_collected_count
        underreported_data_types_count
      }
      app_store_privacy_details {
        app_store_privacy_screenshot_url
        app_store_privacy_screenshot_url_date
        data_records_collected_count
        data_records_shared_with_sdks_count
        data_theorem_observed_privacy_data {
          apple_data_category
          apple_data_category_name_en
          is_underreported
        }
        embedded_sdk_count
        downloaded_in_countries {
          alpha2
          name_en
        }
        app_store_privacy_data {
          apple_data_category
          apple_data_category_name_en
        }
      }
      all_communication_encrypted
      does_not_offload_data_to_third_parties
      enhances_password_security
      full_protection_on_untrusted_networks
      leverages_secure_browser_settings
      leverages_secure_system_libraries
      leverages_secure_system_sdk
      leverages_secure_system_settings
      protects_data_on_screens
      protects_the_keyboard
      removes_data_from_shared_device_locations
      requires_android_device_security
      requires_android_malware_protection
      server_enforces_https
      supports_two_factor_authentication
      trustkit_url
    }
  }
`;

export const list = gql`
  query MobileAppsList(
    $cursor: String
    $platform: MobileAppPlatform
    $include: [String!]
    $page_size: Int
    $bundle_id: String
    $name: String
    $release_type: MobileAppReleaseType
    $subscription: MobileAppSubscription
    $updated_since: Date
  ) {
    mobile_apps_list(
      cursor: $cursor
      filter: SCAN_AND_SECURE_APPS
      platform: $platform
      include: $include
      page_size: $page_size
      bundle_id: $bundle_id
      name: $name
      release_type: $release_type
      subscription: $subscription
      updated_since: $updated_since
    ) {
      pagination_information {
        next_cursor
        total_count
      }
      mobile_apps {
        id
        name
        platform
        app_protection_score
        app_protection_score_ratio
        blocked_scan_reason
        category
        app_secure_id
        integrations {
          ci_cd
          issue_tracker
          trust_kit
        }
        max_app_protection_score
        subscription
        release_type
        bundle_id
        is_enterprise_internal
        app_store_customer_mobile_app_id
        icon_url
        store_url
        date_updated
        date_created
        scan_status
        most_recent_scan {
          id
          date_created
          date_updated
          mobile_app_id
          app_version
          start_date
          end_date
        }
        metadata {
          closed_security_finding_apple_app_store_blocker_beyond_subscription_level_count
          closed_security_finding_apple_app_store_blocker_count
          closed_security_finding_beyond_subscription_level_count
          closed_security_finding_count
          closed_security_finding_google_play_blocker_beyond_subscription_level_count
          closed_security_finding_google_play_blocker_count
          closed_security_finding_high_beyond_subscription_level_count
          closed_security_finding_high_count
          closed_security_finding_low_beyond_subscription_level_count
          closed_security_finding_low_count
          closed_security_finding_medium_beyond_subscription_level_count
          closed_security_finding_medium_count
          closed_security_finding_priority_alerts_beyond_subscription_level_count
          closed_security_finding_priority_alerts_count
          closed_security_finding_security_p1_beyond_subscription_level_count
          closed_security_finding_security_p1_count
          closed_security_finding_regulatory_compliance_count
          closed_security_finding_regulatory_compliance_beyond_subscription_level_count
          open_insight_finding_count
          open_sdk_finding_beyond_subscription_level_count
          open_sdk_finding_beyond_subscription_level_with_open_issues_beyond_subscription_level_count
          open_sdk_finding_count
          open_sdk_finding_with_open_issues_beyond_subscription_level_count
          open_sdk_finding_with_open_issues_count
          open_security_finding_apple_app_store_blocker_beyond_subscription_level_count
          open_security_finding_apple_app_store_blocker_count
          open_security_finding_beyond_subscription_level_count
          open_security_finding_count
          open_security_finding_google_play_blocker_beyond_subscription_level_count
          open_security_finding_google_play_blocker_count
          open_security_finding_high_beyond_subscription_level_count
          open_security_finding_high_count
          open_security_finding_low_beyond_subscription_level_count
          open_security_finding_low_count
          open_security_finding_medium_beyond_subscription_level_count
          open_security_finding_medium_count
          open_security_finding_priority_alerts_beyond_subscription_level_count
          open_security_finding_priority_alerts_count
          open_security_finding_security_p1_beyond_subscription_level_count
          open_security_finding_security_p1_count
          open_security_finding_regulatory_compliance_count
          open_security_finding_regulatory_compliance_beyond_subscription_level_count
        }
        keys_to_the_kingdom {
          open_security_finding_count
          open_security_finding_target_count
        }
        coffee_shop_compromise {
          open_security_finding_count
          open_security_finding_target_count
        }
        app_store_privacy {
          data_record_types_collected_count
          underreported_data_types_count
        }
        app_store_privacy_details {
          app_store_privacy_screenshot_url
          app_store_privacy_screenshot_url_date
          data_records_collected_count
          data_records_shared_with_sdks_count
          data_theorem_observed_privacy_data {
            apple_data_category
            apple_data_category_name_en
            is_underreported
          }
          embedded_sdk_count
          downloaded_in_countries {
            alpha2
            name_en
          }
          app_store_privacy_data {
            apple_data_category
            apple_data_category_name_en
          }
        }
        all_communication_encrypted
        does_not_offload_data_to_third_parties
        enhances_password_security
        full_protection_on_untrusted_networks
        leverages_secure_browser_settings
        leverages_secure_system_libraries
        leverages_secure_system_sdk
        leverages_secure_system_settings
        protects_data_on_screens
        protects_the_keyboard
        removes_data_from_shared_device_locations
        requires_android_device_security
        requires_android_malware_protection
        server_enforces_https
        supports_two_factor_authentication
        trustkit_url
      }
    }
  }
`;

// We need to provide the default filter of SCAN_AND_SECURE apps for practically
// all use cases within Mobile Secure. However, that filter is incompatible with
// other query parameters in some rare cases and therefore the need for this option.
export const list_without_default_filter = gql`
  query MobileAppsListWithFilter(
    $cursor: String
    $platform: MobileAppPlatform
    $filter: MobileAppListFilter
    $include: [String!]
    $page_size: Int
    $bundle_id: String
    $name: String
    $release_type: MobileAppReleaseType
    $subscription: MobileAppSubscription
    $updated_since: Date
  ) {
    mobile_apps_list(
      cursor: $cursor
      filter: $filter
      platform: $platform
      include: $include
      page_size: $page_size
      bundle_id: $bundle_id
      name: $name
      release_type: $release_type
      subscription: $subscription
      updated_since: $updated_since
    ) {
      pagination_information {
        next_cursor
        total_count
      }
      mobile_apps {
        id
        name
        platform
        app_protection_score
        app_protection_score_ratio
        blocked_scan_reason
        category
        app_secure_id
        integrations {
          ci_cd
          issue_tracker
          trust_kit
        }
        max_app_protection_score
        subscription
        release_type
        bundle_id
        is_enterprise_internal
        app_store_customer_mobile_app_id
        icon_url
        store_url
        date_updated
        date_created
        scan_status
        most_recent_scan {
          id
          date_created
          date_updated
          mobile_app_id
          app_version
          start_date
          end_date
        }
        metadata {
          closed_security_finding_apple_app_store_blocker_beyond_subscription_level_count
          closed_security_finding_apple_app_store_blocker_count
          closed_security_finding_beyond_subscription_level_count
          closed_security_finding_count
          closed_security_finding_google_play_blocker_beyond_subscription_level_count
          closed_security_finding_google_play_blocker_count
          closed_security_finding_high_beyond_subscription_level_count
          closed_security_finding_high_count
          closed_security_finding_low_beyond_subscription_level_count
          closed_security_finding_low_count
          closed_security_finding_medium_beyond_subscription_level_count
          closed_security_finding_medium_count
          closed_security_finding_priority_alerts_beyond_subscription_level_count
          closed_security_finding_priority_alerts_count
          closed_security_finding_security_p1_beyond_subscription_level_count
          closed_security_finding_security_p1_count
          closed_security_finding_regulatory_compliance_count
          closed_security_finding_regulatory_compliance_beyond_subscription_level_count
          open_insight_finding_count
          open_sdk_finding_beyond_subscription_level_count
          open_sdk_finding_beyond_subscription_level_with_open_issues_beyond_subscription_level_count
          open_sdk_finding_count
          open_sdk_finding_with_open_issues_beyond_subscription_level_count
          open_sdk_finding_with_open_issues_count
          open_security_finding_apple_app_store_blocker_beyond_subscription_level_count
          open_security_finding_apple_app_store_blocker_count
          open_security_finding_beyond_subscription_level_count
          open_security_finding_count
          open_security_finding_google_play_blocker_beyond_subscription_level_count
          open_security_finding_google_play_blocker_count
          open_security_finding_high_beyond_subscription_level_count
          open_security_finding_high_count
          open_security_finding_low_beyond_subscription_level_count
          open_security_finding_low_count
          open_security_finding_medium_beyond_subscription_level_count
          open_security_finding_medium_count
          open_security_finding_priority_alerts_beyond_subscription_level_count
          open_security_finding_priority_alerts_count
          open_security_finding_security_p1_beyond_subscription_level_count
          open_security_finding_security_p1_count
          open_security_finding_regulatory_compliance_count
          open_security_finding_regulatory_compliance_beyond_subscription_level_count
        }
        keys_to_the_kingdom {
          open_security_finding_count
          open_security_finding_target_count
        }
        coffee_shop_compromise {
          open_security_finding_count
          open_security_finding_target_count
        }
        app_store_privacy {
          data_record_types_collected_count
          underreported_data_types_count
        }
        app_store_privacy_details {
          app_store_privacy_screenshot_url
          app_store_privacy_screenshot_url_date
          data_records_collected_count
          data_records_shared_with_sdks_count
          data_theorem_observed_privacy_data {
            apple_data_category
            apple_data_category_name_en
            is_underreported
          }
          embedded_sdk_count
          downloaded_in_countries {
            alpha2
            name_en
          }
          app_store_privacy_data {
            apple_data_category
            apple_data_category_name_en
          }
        }
        all_communication_encrypted
        does_not_offload_data_to_third_parties
        enhances_password_security
        full_protection_on_untrusted_networks
        leverages_secure_browser_settings
        leverages_secure_system_libraries
        leverages_secure_system_sdk
        leverages_secure_system_settings
        protects_data_on_screens
        protects_the_keyboard
        removes_data_from_shared_device_locations
        requires_android_device_security
        requires_android_malware_protection
        server_enforces_https
        supports_two_factor_authentication
        trustkit_url
      }
    }
  }
`;

export default {
  list,
  list_without_default_filter,
  get,
  getGoogleSafetyCsv,
};
