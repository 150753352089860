//@flow
import { getUserAccount } from '@dt/session';

// NOTE: 'Error' is provided here for legacy reasons.
//       Ideally this should be null indicating the backend did not return an error message.
//       But, changing it has downstream effects for APIs that haven't been updated to include
//       an error message - Assumptions are currently that an error message is provided at this level.
export const DEFAULT_ERROR_MESSAGE = 'Error';

/**
 * In some cases, a non-portal user will be given a temporary token to access a specific content
 * Since there is no need for it to be to be cached locally, we store here as just a constant
 * and mutate it when given one
 */
let temporaryScopedAccessToken = null;
let isTemporaryScopedAccessTokenForced = false;

export function setTemporaryToken(token: string) {
  temporaryScopedAccessToken = token;
}

export function forceUseTemporaryToken() {
  isTemporaryScopedAccessTokenForced = true;
}

async function authorizedFetch(
  requestInfo: RequestInfo,
  requestOptions?: RequestOptions,
): Promise<Response> {
  const userAccount = await getUserAccount();
  const sessionId = !userAccount.no_session_reason
    ? userAccount.sessionId
    : null;

  let authValue =
    typeof sessionId === 'string'
      ? `Session ${sessionId}`
      : typeof temporaryScopedAccessToken === 'string'
      ? `ScopedAccessToken ${temporaryScopedAccessToken}`
      : '';

  if (isTemporaryScopedAccessTokenForced && temporaryScopedAccessToken) {
    authValue = `ScopedAccessToken ${temporaryScopedAccessToken}`;
  }

  const patchedInit = {
    ...requestOptions,
    headers: {
      Authorization: authValue,
    },
  };

  // Remove keys with `null` or `undefined` values
  for (let [k, v] of Object.entries(requestOptions?.headers || {})) {
    if (v === null || v === undefined) {
      let { [k]: _, ...rest } = patchedInit.headers;
      patchedInit.headers = rest;
    } else {
      patchedInit.headers[k] = v;
    }
  }

  let response = await fetch(requestInfo, patchedInit);
  return response;
}

export default authorizedFetch;

type APIResponse<R> = {
  _type: 'response',
  body: R,
  status: number,
  ok: true,
};

type APIError = {
  _type: 'error',
  title: string,
  description: string,
  status: number,
  ok: false,
};

export type APIResult<R> = Promise<APIResponse<R> | APIError>;

// This is a rare case where `any` is difficult to avoid. Since we're dealing
// with network responses, Flow naturally types `response.json()` as `any`.
// `parse` is a generic function that can be used by any request. The only way
// to disambiguate `response.json()` is to provide a union of all possible
// network responses as the type. Even then, this is basically meaningless
// because we'd need to refine the union down to one type when `parse` is
// called. Another way of saying this is that the responsibility for knowing
// the actual response type lies with the code that called `fetch` with a
// specific url, since the url is what deteremines the response. Hence, this
// function cannot use a generic type because Flow complains that the generic
// type is never instantiated... it's basically unknown when `parse` is called.
// Instead, we basically pass the `any` from `response.json()` forward into the
// FetchResponse, and allow the callsite to determine the type, just as it
// would have to do if it was calling `response.json()` itself. We just need to
// make sure that any time parse is called, the `any` is case to the correct
// type.
export async function parse(response: Response): APIResult<any> {
  let body = {};
  let contentType = response.headers.get('content-type');
  try {
    if (contentType?.includes('application/json')) {
      body = await response.json();
    } else {
      let text = await response.text();
      body = ({ text: text }: any);
    }
  } catch (e) {
    console.error(e);
  }

  // Workaround to handle the 202 errors
  if (response.status === 202) {
    return {
      _type: 'error',
      title: 'Error 202',
      description: '',
      status: response.status,
      ok: false,
    };
  }

  if (response.ok) {
    return {
      _type: 'response',
      body,
      status: response.status,
      ok: true,
    };
  } else {
    let title = body.title || body.text || DEFAULT_ERROR_MESSAGE;
    // horizon errors look like:   { title: string, description: string }
    // sevenhell errors look like: { error: { message: string }}
    //                sevenhell               horizon             any other error
    let description = body?.error?.message || body.description || null;
    return {
      _type: 'error',
      title,
      description,
      status: response.status,
      ok: false,
    };
  }
}
